export namespace ATTEND_REQUEST_STATUS {
  export const AWAY = 'StateAbstractStatus::Away'
  export const LISTENING = 'StateAbstractStatus::Listening'
  export const WAITING_FOR_VIDEO = 'StateAbstractStatus::WaitingForRTC'
  export const BUSY = 'StateAbstractStatus::Busy'
  export const REQUESTING = 'StateAbstractStatus::Requesting'
  export const Established = 'StateAbstractStatus::Established'
}

export type AttendRequestStatus = typeof ATTEND_REQUEST_STATUS[keyof typeof ATTEND_REQUEST_STATUS]

export namespace STAFF_STATUS {
  export const OFFLINE = 'Offline'
  export const AWAY = 'AWAY'
  export const LISTENING = 'LISTENING'
  export const WAITING_FOR_ACK = 'WaitingForACK'
  export const WAITING_FOR_VIDEO_CONNECTION = 'WaitingForRTC'
  export const BUSY = 'Busy'
}

export type StaffStatus = typeof STAFF_STATUS[keyof typeof STAFF_STATUS]

export namespace VISITOR_STATUS {
  export const OFFLINE = 'Offline'
  export const WAITING_FOR_STATUS = 'WAITING_FOR_STATUS'
  export const SOCKET_CONNECTED = 'SOCKET_CONNECTED'
  export const READY = 'READY'
  export const WAITING_FOR_ACCEPTED = 'WAITING_FOR_ACCEPTED'
  export const WAITING_FOR_VIDEO_CONNECTION = 'WaitingForRTC'
  export const BUSY = 'BUSY'
  export const LEAVING = 'LEAVING'
  export const ERROR = 'Error'
}

export type VisitorStatus = typeof VISITOR_STATUS[keyof typeof VISITOR_STATUS]

export namespace STAFF_MESSAGE_TYPE {
  export const RESPOND_STATUS = 'respondStatus'
  export const COMEBACK = 'comeback'
  export const LEAVE = 'leave'
  export const ACCEPT = 'accept'
  export const STAFF_DISCONNECTED_RTC = 'staffDisconnectedRTC'
  export const ATTACH_IMAGE = 'attachImage'
  export const ATTACH_URL = 'attachURL'
  export const FORCE_RELOAD = 'forceReload'
  export const STAFF_REJECTION = 'reject'
  export const REQUEST_RECONNECT = 'requestReconnect'
  export const REQUEST_CURRENT_STATUS = 'request_current_status'
  export const JOIN_TALKING = 'staffJoinTalking'
  export const LEAVE_TALKING = 'staffLeaveTalking'
  export const EXIST_RESPONSE = 'staffExistResponse' // TODO: 名前変える
  export const START_PDF_SHARE_SUBSCRIBE = 'startPdfShareSubscribe'
  export const FINISH_PDF_SHARE_SUBSCRIBE = 'finishPdfShareSubscribe'
  export const ACCEPT_GROUP_CALLS = 'acceptGroupCalls'
}

export type StaffMessageType = typeof STAFF_MESSAGE_TYPE[keyof typeof STAFF_MESSAGE_TYPE]

export namespace VISITOR_MESSAGE_TYPE {
  export const REQUEST_STATUS = 'requestStatus'
  export const REQUEST_ATTEND = 'requestAttend'
  export const CANCEL_ATTEND = 'cancelAttend'
  export const ACKNOWLEDGE = 'acknowledge'
  export const VISITOR_DISCONNECTION = 'customerDisconnectedRTC'
  export const VISITOR_REJECTION = 'reject'
  export const RECONNECTABLE = 'reconnectable'
  export const NETWORK_STATE = 'network_state'
  export const CURRENT_STATUS = 'current_status'
  export const CLOSE_VISIT = 'close_visit'
  export const DISCONNECTING_TALK = 'disconnectingTalk'
}

export type VisitorMessageType = typeof VISITOR_MESSAGE_TYPE[keyof typeof VISITOR_MESSAGE_TYPE]

export namespace ATTEND_TEL_ATTRIBUTE_NAME {
  export const TEL_NUMBER = '問い合わせ先電話番号'
  export const IMAGE_URL = '問い合わせ先表示画像URL'
}

export type AttendTelAttributeName = typeof ATTEND_TEL_ATTRIBUTE_NAME[keyof typeof ATTEND_TEL_ATTRIBUTE_NAME]

export namespace VISITOR_WEBRTC_SETTINGS {
  export const VISITOR_THANKS_AUTO_REDIRECT = 'サンクス画面自動表示'
  export const VISITOR_VIDEO_POSITION = 'visit_video_position' // anywhere_store['preferences]['visit_video_position']
  export const VISITOR_VIDEO_SCALE = 'visit_video_scale' // anywhere_store['preferences]['visit_video_scale']
  export const VISITOR_VIDEO_HIDDEN = '来訪者のビデオ非表示'
  export const STAFF_VIDEO_HIDDEN = 'スタッフのビデオ非表示'
}

export type VisitorWebrtcSettings = typeof VISITOR_WEBRTC_SETTINGS[keyof typeof VISITOR_WEBRTC_SETTINGS]

export namespace VISITOR_CONTINUE_SENDING_MESSAGE {
  export const INTERVAL = 1000
  export const LIMIT = 50
}

export type VisitorContinueSendingMessage = typeof VISITOR_CONTINUE_SENDING_MESSAGE[keyof typeof VISITOR_CONTINUE_SENDING_MESSAGE]

export const VISIT_WINDOW_RELOAD_EVENT = 'visit-window-reload'

export const WSAGENCY_WINDOW_PLACE_CHANGED = 'wsagency-window-place-changed'

export const MEDIA_DEVICE_KEY = 'anywhere_store_media_device'

export interface MediaDeviceSetting {
  videoId: string
  audioId: string
}

export namespace COMMON_MUTATION {
  export const CHANGE_MEDIA_DEVICE_SETTING_MODAL_OPEN = 'CHANGE_MEDIA_DEVICE_SETTING_MODAL_OPEN'
  export const CHAT_MODAL_OPEN = 'CHAT_MODAL_OPEN'
  export const SET_MEDIA_DEVICE_SETTING = 'SET_MEDIA_DEVICE_SETTING'
}

export namespace COMMON_ACTION {
  export const MEDIA_DEVICE_INITIALIZE = 'MEDIA_DEVICE_INITIALIZE'
}

export const VIEWABLE_MINUTES_KEY = 'viewable_minutes'
export const VIEWABLE_REMAINING_TIME_KEY = 'viewable_remaining_time'
export const VIEWABLE_TIMEOUT_URL_KEY = 'viewable_timeout_url'

export namespace SOCKET_STATUS {
  export const NOT_CONNECTED = 'NOT_CONNECTED'
  export const CONNECTED = 'CONNECTED'
  export const RECONNECT_FAILED = 'RECONNECT_FAILED'
}
