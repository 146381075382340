// localStorageやsessionStorageが使えるか判定する
// https://developer.mozilla.org/ja/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
export function storageAvailable(type: string): boolean {
  let storage

  try {
    storage = window[type]
    const x = '__storage_test__'
    storage.setItem(x, x)
    storage.removeItem(x)
    return true
  } catch (e) {
    return e instanceof DOMException && (
      // everything except Firefox
      e.code === 22 ||
      // Firefox
      e.code === 1014 ||
      // test name field too, because code might not be present
      // everything except Firefox
      e.name === 'QuotaExceededError' ||
      // Firefox
      e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      (storage && storage.length !== 0)
  }
}

// localStorage用
export function localStorageAvailable(): boolean {
  return storageAvailable('localStorage')
}

// sessionStorage用
export function sessionStorageAvailable(): boolean {
  return storageAvailable('sessionStorage')
}
